import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

const IndexPage = () => (
  <Layout>
    <Seo title="404" />

    <div className="">
      <div className="bg-gray-300 w-full h-screen py-16 md:py-32">
        <div className="max-w-5xl mx-auto pt-8 sm:pt-10 lg:pt-12 text-center mb-16">
          <h1 className="text-gray-900 font-bold text-4xl sm:text-5xl md:text-6xl tracking-tight wel-mb-s md:mb-8">
            404
          </h1>
          <h2 className="mb-6 md:mb-8 opacity-50 text-xl md:text-2xl">This page does not exist.</h2>
          <Link to="/" className="btn btn-primary">&larr; Go Back Home</Link>
        </div>

      </div>

    </div>





  </Layout>
)

export default IndexPage
